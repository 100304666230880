<template>
  <div class="confirmation-page">
    <div v-if="status === 'success'" class="content">
      <h1>Anfrage bestätigt!</h1>
      <p class="main-text">Deine Anfrage wurde erfolgreich bestätigt und wird nun vom System überprüft.</p>
      <p class="info-text">
        Sobald die Überprüfung abgeschlossen ist, wird deine Anfrage auf Instagram (@spottedhsk) veröffentlicht. Falls du Fragen hast oder deine Anfrage zurückziehen möchtest, kannst du uns jederzeit unter <a href="mailto:support@spottedhsk.de">support@spottedhsk.de</a> kontaktieren.
      </p>
      <router-link to="/">
        <button class="back-button">Zurück zur Startseite</button>
      </router-link>
    </div>
    <div v-else-if="status === 'loading'" class="content">
      <p class="loading-text">Bestätigung wird verarbeitet...</p>
    </div>
    <div v-else class="content">
      <h1>Fehler bei der Bestätigung</h1>
      <p class="main-text">Diese Anfrage wurde bereits bestätigt oder existiert nicht.</p>
      <p class="info-text">
        Falls du der Meinung bist, dass dies ein Fehler ist, kontaktiere uns bitte unter <a href="mailto:support@spottedhsk.de">support@spottedhsk.de</a>.
      </p>
      <router-link to="/">
        <button class="back-button">Zurück zur Startseite</button>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RequestConfirmationPage',
  data() {
    return {
      status: 'loading', // Werte: 'loading', 'success', 'error'
    };
  },
  async created() {
    const token = this.$route.query.token;
    if (!token) {
      this.status = 'error'; // Kein Token vorhanden
      return;
    }

    try {
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/confirm-request`, {
        params: { token },
      });

      if (response.status === 200) {
        this.status = 'success';
      } else {
        this.status = 'error';
      }
    } catch (error) {
      this.status = 'error';
    }
  },
};
</script>

<style scoped>
.confirmation-page {
  max-width: 500px;
  margin: 0 auto;
  padding: 30px;
  background-color: transparent;
  color: #f0f0f0;
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.content {
  text-align: center;
}

h1 {
  font-size: 2em;
  font-weight: bold;
  color: #4ade80;
  margin-bottom: 20px;
}

.main-text {
  font-size: 1em;
  color: #f0f0f0;
  margin: 20px 0;
}

.info-text {
  font-size: 0.85em;
  color: #b0b0b0;
  margin-top: 20px;
  line-height: 1.4;
}

.info-text a {
  color: #4ade80;
  text-decoration: none;
}

.info-text a:hover {
  text-decoration: underline;
}

.loading-text {
  font-size: 1em;
  color: #b0b0b0;
}

.back-button {
  width: 75%;
  padding: 15px;
  font-size: 1em;
  background-color: #555555;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.back-button:hover {
  background-color: #777777;
}

</style>
